import React from "react";
import InputText from "../../../../../../../commonComponents/inputs/InputText/InputText";
import FormAdd from '../../../../../../../commonComponents/forms/FormAdd/FormAdd';
import useLogicFormCancelTraining from './hooks/useLogicFormCancelTraining';

const FormCancelTraining = ({
    handleSucces,
    training,
}) => {

    const {
        notification, setNotification,
        validMessage, setValidMessage,
        isValidForm,
        isLoading,
        onSubmit,
    } = useLogicFormCancelTraining({
        handleSucces,
        training,
    });

    return (
        <FormAdd
            onSubmit={onSubmit}
            text={'Отменить тренировку'}
            textButtonSubmit={'Отменить'}
            validationOptions={!isValidForm} // дисейблить сабмит если true
            textForFill={validMessage}
            cleanTextForFill={() => setValidMessage()}
            isLoading={isLoading}
            buttonUnderText={validMessage}
            children={
                <>
                    <InputText
                        inputValue={notification}
                        setInputChange={setNotification}
                        label={'Текст для клиентов'}
                        placeholder='Текст для клиентов'
                    />
                </>
            }
        />
    )
}

export default FormCancelTraining;