import React from "react";
import FormAdd from "../../../../../../../commonComponents/forms/FormAdd/FormAdd";
import useLogicCreateOrUpdateTrainingSettingsForm from "./logic/useLogicCreateOrUpdateTrainingSettingsForm";
import InputText from "../../../../../../../commonComponents/inputs/InputText/InputText";
import InputSelect from "../../../../../../../commonComponents/inputs/InputSelect/InputSelect";
import FormPaymentSetting from '../../../FormPaymentSetting/FormPaymentSetting'
import ColorPickerInput from "../../../../../../../commonComponents/inputs/ColorPickerInput/ColorPickerInput.tsx";
import styled from "styled-components";

function CreateOrUpdateTrainingSettingsForm({ handleSuccessCreate, handleSuccessUpdate, selectedTrainingSetting }) {

    const {
        isLoading,
        dataSetting, setDataSetting,
        isValidForm,
        validationText, setValidationText,
        create,
        update,
    } = useLogicCreateOrUpdateTrainingSettingsForm({ handleSuccessCreate, handleSuccessUpdate, selectedTrainingSetting });

    const typesTrainings = [
        { label: "групповая", value: "групповая" },
        { label: "персональная", value: "персональная" },
        { label: "сплит", value: "сплит" },
        { label: "настраиваемая", value: "настраиваемая" },
    ]

    return (
        <FormAdd
            text={!selectedTrainingSetting ? 'Добавить тип' : 'Редактировать'}
            onSubmit={!selectedTrainingSetting ? create : update}
            textButtonSubmit={!selectedTrainingSetting ? 'Добавить' : 'Редактировать'}
            isLoading={isLoading}
            textForFill={validationText}
            cleanTextForFill={() => setValidationText()}
            validationOptions={!isValidForm}
            children={
                <>
                    <InputSelect
                        label={'Тип'}
                        placeholder={'Тип тенировки'}
                        options={typesTrainings}
                        value={dataSetting.type}
                        onChange={(value) => setDataSetting((prev) => ({ ...prev, type: value }))}
                        isRequired={true}
                    />
                    <ColorPickerInput
                        value={dataSetting.color}
                        onChange={(value) => setDataSetting((prev) => ({ ...prev, color: value }))}
                    />
                    {dataSetting.type === 'настраиваемая' &&
                        <>
                            <InputText
                                label={'Название'}
                                placeholder={'Название тренировки'}
                                inputValue={dataSetting.customName}
                                setInputChange={(value) => setDataSetting((prev) => ({ ...prev, customName: value }))}
                                isRequired={false}
                            />
                            <FormPaymentSetting
                                handleDataPayment={(value) => setDataSetting((prev) => ({ ...prev, dataPayment: JSON.stringify(value) }))}
                                handleFormValidate={() => { }}
                            />
                        </>}
                </>
            }
        />
    )
}

export default CreateOrUpdateTrainingSettingsForm;

const ColorInput = styled.div`
display: flex;

`