import { useEffect, useState } from "react";
import { useStudio } from "../../../../../../../../context/studioContext";
import apiTraining from "../../../../../../../../api/apiTraining";


function useLogicFormAddOrUpdateTraining({
    handleSuccess,
    training,
}) {
    const { studio } = useStudio();
    const [notification, setNotification] = useState('Отмена тренировки')
    const [validMessage, setValidMessage] = useState();
    const [isValidForm, setValidForm] = useState(true);
    const [isLoading, setIsLoading] = useState(false);

    async function onSubmit() {
        try {
            setIsLoading(true);
            const res = await apiTraining.cancelTraining({ trainingId: training._id, companyBranchId: studio._id, notification })
            setValidMessage(res.message);
            if (res.status === 200) {
                handleSuccess(res.training);
            }
        }
        catch (err) {
            setValidMessage(err.message);
        } finally {
            setIsLoading(false);

        }
    }

    const optionsDate = {
        day: 'numeric',
        month: 'numeric',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: false,
        timeZone: 'Asia/Yekaterinburg',
    };

    const formatterDate = new Intl.DateTimeFormat('ru-RU', optionsDate);

    useEffect(() => {
        if (training) {
            const formattedDate = formatterDate.format(new Date(training.dateOn));
            const info = `Отмена тренировки ${training.name} - ${formattedDate}`
            setNotification(info);
        }
    }, [training])



    return {
        notification, setNotification,
        validMessage, setValidMessage,
        isValidForm,
        onSubmit,
        isLoading,
    };
}

export default useLogicFormAddOrUpdateTraining;
