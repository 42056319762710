import React, { useEffect, useState } from "react";
import CircleIndicator from "../../indicators/CircleIndicator";
import AbonementInfo from "./copmponents/AbonementInfo";
import { getDaysDifference } from "../../../utils/date";
import { useStudio } from "../../../context/studioContext";

// Отрисовка группового абонемента на странице Админа
function AbonementNameAndIndicator({ abonement,handleClickOnAbonement }) {
    const { objStyles } = useStudio();
    const name = abonement?.type?.customName ? abonement.type.customName : 'Абонемент';
    const value = abonement ? 'абонемент есть' : 'отсутсвует';
    const [colorIdicator, setColorIndicator] = useState('green');

    useEffect(() => {
        if (abonement) {
            const daysDifference = getDaysDifference(new Date(abonement.dateTo))
            const quantityVisits = Number(abonement.quantityVisits);

            if (daysDifference < 0 || quantityVisits < 1) {
                setColorIndicator('red')
            } else if (
                (daysDifference < 3 && daysDifference > 0)
                && quantityVisits > 0
            ) {
                setColorIndicator('yellow')
            } else {
                setColorIndicator('green')
            }
        } else {
            setColorIndicator('red')
        }
    }, [abonement])

    return (
        <div className="abonement-info" onClick={handleClickOnAbonement}>
            <div className="client-info__container-indicator-abonement">
                <p className={`client-info__text font-weight-bold ${objStyles.colorText}`}>{name}</p>
                <CircleIndicator color={colorIdicator} />
            </div>
            <AbonementInfo abonement={abonement} />
        </div>
    )
}

export default AbonementNameAndIndicator;