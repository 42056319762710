import React from 'react';
import ClientInfo from '../../../../commonComponents/pages/ClientInfo/ClientInfo'
import ListWithOnClickElement from '../../../../commonComponents/pages/ListWithOnClickElement/ListWithOnClickElement';
import Preloader from '../../../../commonComponents/loaders/Preloader/Preloader';
import { useStudio } from '../../../../context/studioContext';


/** Компонент работы со списком клиентов, записанных на трениовку */
function ClientsListOnTraining({ clients, isLoading, handleClickOnClient }) {
    const { objStyles } = useStudio();
    return (
        <div className='container-padding-top'>
            <p className={`title ${objStyles.colorText}`}>Список клиентов</p>
            {!isLoading ?
                <div className='margin-top__30px'>
                    <ListWithOnClickElement
                        array={clients}
                        handleClickOnElement={(client) => handleClickOnClient(client)}
                        children={
                            (client) => <ClientInfo client={client} />
                        }
                    />
                </div>
                : <Preloader />}
        </div>
    )
}



export default ClientsListOnTraining;
