import React, { useState } from "react";
import styled from "styled-components";
import { HexColorPicker } from "react-colorful";

interface ColorPickerInputProps {
    value: string;
    onChange: (color: string) => void;
}

const ColorPickerInput: React.FC<ColorPickerInputProps> = ({ value, onChange = () => { } }) => {
    const [isPickerOpen, setIsPickerOpen] = useState(false);

    const handleColorChange = (color: string) => {
        onChange(color);
    };

    return (
        <Wrapper>
            <ColorPreview
                style={{ backgroundColor: value }}
                onClick={() => setIsPickerOpen(!isPickerOpen)}
            />
            <Text>Цвет тренировки</Text>
            {isPickerOpen && (
                <PickerWrapper>
                    <HexColorPicker color={value} onChange={handleColorChange} />
                    <Button onClick={() => setIsPickerOpen(false)}>выбрать цвет</Button>
                </PickerWrapper>
            )}
        </Wrapper>
    );
};

export default ColorPickerInput;

// Styled Components
const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: relative;
  gap: 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
`;

const ColorPreview = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 4px;
  cursor: pointer;
  border: 1px solid #ddd;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const PickerWrapper = styled.div`
  position: absolute;
  top: 50px;
  left: 0;
  z-index: 10;
  background: white;
  border: 1px solid #ddd;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  padding: 10px;
`;

const Text = styled.span`
  font-size: 17px;
  font-weight: 400;
  color: #000000;
`;

const Button = styled.button`
  background-color: #000000;
  font-size: 12px;
  font-weight: 400;
  color: white;
  padding: 5px 10px;
  border-radius: 4px;
  margin-top: 5px;
`;

const Container = styled.div`
  display: flex;
  fle
  `