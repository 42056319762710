import React from 'react';
import { useStudio } from '../../context/studioContext';
import LoaderNames from '../loaders/LoaderNames/LoaderNames';
import { useUserContext } from '../../context/userContext';
const LoaderPage = () => {
  const { objStyles } = useStudio()
  const userData = useUserContext();

  return (
    <div className={`loader-page-overlay ${userData ? objStyles.backgroundColor : 'background-color__dark'}`}>
      <LoaderNames />
    </div>
  );
};

export default LoaderPage;
