import { useState } from "react";
import apiClient from "../../../../../../api/apiClient";
import { useStudio } from "../../../../../../context/studioContext";
import apiTraining from "../../../../../../api/apiTraining";
import apiNonMemberShipVisit from "../../../../../../api/apiNonMemberShipVisit";

function useLogicFormAddByNameOrPhone({ training, handleSuccessAddVisit }) {
    const { studio } = useStudio();
    const [clients, setClients] = useState([]);
    const [selectedClient, setSelectedClient] = useState();
    const [loading, setLoading] = useState();
    const [openedForm, setOpenedForm] = useState('');
    const [validMessage, setValidMessage] = useState();
    const [isMembership, setIsMembership] = useState(true);

    async function search(target) {
        try {
            setLoading(true);
            const filtredStroke = target.replace(/[^a-zA-Zа-яА-Я0-9\s.,()-]/g, '');
            const data = await apiClient.getClientsBySearchString(filtredStroke, studio._id)
            setClients(data.clients)
            setLoading(false);
        }
        catch (err) {
            setLoading(false);
            console.log(err);
        }
    }

    function сlickOnClient(client) {
        setSelectedClient(client);
        setOpenedForm("FormTwoTextButton")
    }

    async function addVisit() {
        try {
            setLoading(true);
            if (!isMembership) {
                await apiNonMemberShipVisit.create({ clientId: selectedClient._id, trainingId: training._id, companyBranchId: studio._id })
            } else {
                await apiTraining.addClientByIdToVisitList({ clientId: selectedClient._id, trainingId: training._id, companyBranchId: studio._id })
            }
            handleSuccessAddVisit(selectedClient)
            setLoading(false);
        }
        catch (err) {
            console.log(err);
            if (err.message.includes('Нет действующего абонемента')) {
                setIsMembership(false);
            } else {
                setValidMessage(err.message)
            }
            setLoading(false);
        }
    }

    return ({
        search,
        clients,
        selectedClient,
        loading,
        openedForm, setOpenedForm,
        validMessage, setValidMessage,
        addVisit,
        сlickOnClient,
        isMembership,
    })
}

export default useLogicFormAddByNameOrPhone;