import { useEffect, useState } from "react";
import { useStudio } from "../../../../../../../../context/studioContext";
import { validateForm } from "./utils/utils";
import apiTrainingSettings from "../../../../../../../../api/apiTrainingSettings";

function useLogicCreateOrUpdateTrainingSettingsForm({ handleSuccessCreate, handleSuccessUpdate, selectedTrainingSetting }) {
    const { studio } = useStudio();
    const [isLoading, setIsLoading] = useState();
    const [dataSetting, setDataSetting] = useState({ type: "", customName: undefined, companyBranchId: studio && studio._id, color: "#FFFFFF" });
    const [isValidForm, setIsValidForm] = useState(false);
    const [validationText, setValidationText] = useState();

    async function create() {
        try {
            setIsLoading(true)
            const trainingSettings = await apiTrainingSettings.create(dataSetting)
            handleSuccessCreate(trainingSettings)
            setIsLoading(false)
        }
        catch (err) {
            setValidationText(err.message)
            setIsLoading(false)
        }
    }

    async function update() {
        try {
            setIsLoading(true)
            if (dataSetting.customName && dataSetting.customName !== "") {
                dataSetting.customName = `${dataSetting.type}-${dataSetting.customName}`
            }
            console.log(dataSetting)
            const updatedData = { ...dataSetting, trainingSettingsId: selectedTrainingSetting._id }
            const trainingSettings = await apiTrainingSettings.update(updatedData)
            handleSuccessUpdate(trainingSettings)
            setIsLoading(false)
        }
        catch (err) {
            setValidationText(err.message)
            setIsLoading(false)
        }
    }

    //
    useEffect(() => {
        if (dataSetting.type !== 'настраиваемая') {
            delete dataSetting.dataPayment;
        }
    }, [dataSetting])

    //Наполниь если редактирование
    useEffect(() => {
        if (selectedTrainingSetting) {
            const payTypes = { client: "за клиента", training: "за тренировку" }
            const updatedData = {
                type: selectedTrainingSetting.type,
                customName: selectedTrainingSetting.customName,
                companyBranchId: studio && studio._id,
                color: selectedTrainingSetting.color,
            }
            setDataSetting(updatedData)
        }
    }, [selectedTrainingSetting])

    //Валидация
    useEffect(() => {
        const isValid = validateForm(dataSetting)
        setIsValidForm(isValid)
    }, [dataSetting])

    return ({
        isLoading,
        validationText, setValidationText,
        isValidForm,
        create,
        update,
        dataSetting, setDataSetting,
    })
}
export default useLogicCreateOrUpdateTrainingSettingsForm;