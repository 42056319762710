import React from 'react';
import ClientInfo from '../../../../commonComponents/pages/ClientInfo/ClientInfo'
import ListWithOnClickElement from '../../../../commonComponents/pages/ListWithOnClickElement/ListWithOnClickElement';
import Preloader from '../../../../commonComponents/loaders/Preloader/Preloader';
import VisitInfo from './components/VisitInfo';


/** Компонент работы со списком клиентов, посетивших тренировку без абонемента */
function NonMembershipsVisits({ visits, isLoading, handleClickOnVisit }) {
   
    return (
        <div className='non-membership-visits-page__visits'>
            <p className={`title`}>Список посещений без абонементов</p>
            {!isLoading ?
                <div className='margin-top__30px'>
                    <ListWithOnClickElement
                        array={visits}
                        handleClickOnElement={(visit) => handleClickOnVisit(visit)}
                        children={
                            (visit) => <VisitInfo visit={visit} />
                        }
                    />
                </div>
                : <Preloader />}
        </div>
    )
}



export default NonMembershipsVisits;
